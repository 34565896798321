<template>
  <v-row align="center" justify="center">
    <v-col cols="12" md="6" sm="12">
      <v-card>
        <v-card-title>{{
          $t("_user_management._popup_Add_User.content_2")
        }}</v-card-title>
        <v-card-text>
          <v-form ref="formMultiUsers" v-model="valid" lazy-validation>
            <v-file-input
              :label="
                this.$t('_user_management._popup_Add_User.Add_users_Text')
              "
              accept=".xlsx"
              :success="successFile"
              :error="errorFile"
              v-model="multiUserForm.files"
            />

            <vs-row align="end" justify="end">
              <v-col>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="resetMultiAddUserForm"
                      class="mr-2"
                      v-on="on"
                      dark
                      color="warning"
                      >{{ $t("_common.Reset") }}
                    </v-btn>
                    <v-btn
                      @click="addUserFromFile"
                      :disabled="errorFile"
                      v-on="on"
                      elevation="2"
                      color="success"
                      >{{ $t("_common.Add") }}
                    </v-btn>
                  </template>
                  <span>{{
                    $t("_user_management._popup_Add_User.Hover.Create_a_user")
                  }}</span>
                </v-tooltip>
              </v-col>
            </vs-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
require("../../assets/css/neo.css");

export default {
  data() {
    return {
      valid: true,
      show1: false,
      successFile: false,
      errorFile: false,
      userRolesSelect: [],
      userStatusSelect: [],
      validations: {
        userRolesRules: this.$goc.validate.create().required(),
        passwordRules: this.$goc.validate.create().required().min(6),
        emailRules: this.$goc.validate.create().required().email(),
        userNameRules: this.$goc.validate.create().required().min(3).max(20),
        nameRules: this.$goc.validate.create().required().min(2).max(50),
        userStatusRule: this.$goc.validate.create().required(),
      },
      userForm: {
        FirstName: null,
        LastName: null,
        UserName: "",
        Password: "",
        Email: "",
        UserRoles: "",
        Status: "",
        SendEmail: false,
      },
      passwordCheck: false,
      multiUserForm: {
        files: null,
      },
      emailCheckTimer: null,
      emailRegularExp: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    };
  },
  mounted: function () {
    this.$goc.request.common.getRoles((roleList) => {
      this.userRolesSelect = roleList;
    });
    this.$goc.setModule("AddUser", this);

    this.$goc.request.common.getUserStatus((statusList) => {
      this.userStatusSelect = statusList;
    });
  },
  methods: {
    resetMultiAddUserForm() {
      this.$refs.formMultiUsers.reset();
      this.$refs.formMultiUsers.resetValidation();
    },
    addUserFromFile() {
      if (this.multiUserForm.files) {
        let formData = new FormData();
        formData.append("uploadedFile", this.multiUserForm.files);
        this.$goc.console.log(this.files);

        for (var key of formData.entries()) {
          this.$goc.console.log(key[0] + ", " + key[1]);
        }

        this.$goc.request.postMultipart(
          this.$enums.API.FileUpload.Upload,
          formData,
          {
            then: (response) => {
              if (response.IsSuccess) {
                this.$goc.notify.success({
                  title: this.$t("_common.Success"),
                  message: this.$t(
                    "_user_management._popup_Add_User.UserSuccessfullyCreated"
                  ),
                });
              } else {
                this.multiUserForm.files = null;
                this.$goc.notify.success({
                  title: this.$t("_common.Success"),
                  message: this.$t(
                    "_user_management._popup_Add_User.TrySelectingFile"
                  ),
                });
              }
            },
            exception: (error) => {
              this.multiUserForm.files = null;
              this.$goc.notify.error({
                title: this.$t("_common.ServiceErrorMessage"),
                message: this.$t(
                  "_user_management._popup_Add_User.AddExcelErrorMessage"
                ),
              });
            },
          }
        );
      } else {
        this.$goc.notify.info({
          title: this.$t("_user_management.user_edit.Information"),
          message: this.$t("_user_management._popup_Add_User.TrySelectingFile"),
        });
      }
    },
    validate() {
      return !!this.$refs.form.validate();
    },
  },
};
</script>
